<template>
  <div id="brandThing">
     <Else></Else>
     <div class="thing-box">
        <div class="thing-middle">
            <div class="middle1">
                <div class="midddle1-div1">
                   <img :src="one.trademarkUrl">
                </div>
                <div class="midddle1-div2">
                  <div></div>
                  <div>{{one.trademarkName}}</div>
                </div>
                <div class="midddle1-div3">
                     <div>
                      <span>注册号：</span>
                      <span>{{one.registerNum}}</span>
                  </div>
                  <div>
                      <span>分类：</span>
                      <span>{{one.cateName}}</span>
                  </div>
                  <div>
                      <span>企业：</span>
                      <span>{{one.businessName}}</span>
                  </div>
                  <div v-if="one.releaseTime">
                      <span>发布时间：</span>
                      <span>{{one.releaseTime}}</span>
                  </div>
                </div>
            </div>
            <div class="middle2">
              <div class="middle2-title">品牌故事</div>
              <div class="middle2-de">
                  <div v-for="(v,k) in thing" :key="k">
                    <p>{{v}}</p>
                  </div>
              </div>
            </div>
        </div>
     </div>
  </div>
</template>

<script>
import {famous} from "../../api/api";
import Else from '../ui/else.vue';
import moment from "moment";
export default {
  name: 'brandThing',
  data(){
      return{
        arr:[],
        one:{},
        thing:[],
        // timer:''
      }
  },
  components: {
      Else
  },
  computed:{
      
  },
  methods:{
     async getData(){
              let res = await famous()
              // console.log(res)
              if(res.data.code === 200){
                this.arr = res.data.data;
                for(var i = 0;i<this.arr.length;i++){
                  if(this.$route.query.id == this.arr[i].id){
                    this.one = this.arr[i]
                  }
                }
                if(this.one.brandStory){
                  let result=this.one.brandStory.split("\\n");
                    for(var i=0;i<result.length;i++){
                      this.thing.push(result[i]);
                    }
                  }
                }
               if (this.one.releaseTime) {
				   this.one.releaseTime = this.one.releaseTime.slice(0,10)
			   }
              
              // console.log(this.one)
              //子向父发送数据
              this.$nextTick(()=>{
                // var height =  document.documentElement.scrollHeight;
                
                // let timer = setInterval(() => {
                    //需要定时执行的代码
                    // console.log(height)
                    window.parent.postMessage(
                  {
                    data: 889,
                  },
                  "*"
                );
                // },500)
                  
              })
          },
  },
  mounted(){
      this.getData()
  },
  destroyed(){
    // clearInterval(this.timer)
  }
}

</script>

<style scoped>
  #brandThing{
    padding: 80px 0;
  }
  .thing-box{
    width: 1200px;
    margin: 0 auto;
    height: 729px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(85,84,91,0.2700);
    border-radius: 4px;
  }
  .thing-middle{
    width: 1115px;
    margin: 0 auto ;
    height: 615px;
  }
  .thing-middle>div{
    float: left;
    height: 615px;
    margin-top: 54px;
    border: 1px solid #E1DBDB;
  }
  .middle1{
    width: 273px;
    margin-right: 12px;
  }
  .middle1>div{
    margin-left: 24px;
  }
  .midddle1-div1{
    margin: 66px 0 20px;
  }
  .midddle1-div1>img{
    width: 140px;
    /* height: 186px; */
    border: 1px solid #E1DBDB;
  }
  .midddle1-div2{
    height: 33px;
     margin-bottom: 15px!important;
}
.midddle1-div2>div{
    float: left;
}
.midddle1-div2>div:nth-of-type(1){
    width: 3px;
    height: 22px;
    background: #2553B3;
    margin-right: 6px;
    margin-top: 6px;
   
}
.midddle1-div2>div:nth-of-type(2){
    font-size: 24px;
}
.midddle1-div3>div{
  margin-bottom: 10px;
}
.midddle1-div3>div>span:nth-of-type(1){
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3F3F3F;
}
.midddle1-div3>div>span:nth-of-type(2){
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #626262;
}
.middle2{
  width: 825px;
}
.middle2-title{
  padding-left: 20px;
  height: 54px;
  background: #F4F4F4;
  line-height: 54px;
  font-weight: 600;
  color: #2F2F2F;
  font-size: 24px;
  border-bottom: 1px solid #E4E4E4;
}
.middle2-de{
  width: 776px;
  height: 510px;
  margin: 12px auto 0;
  padding: 15px;
  border: 1px solid #EFEBEB;
  overflow-y: auto;
}
   .middle2-de::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        }

.middle2-de::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.middle2-de::-webkit-scrollbar-track {
  opacity: 0;
}
.middle2-de>div{
  margin: 10px 0 5px;
}
.middle2-de>div>p{
  font-size: 14px;
  color: #2F2F2F;
  text-indent: 2em;
  letter-spacing:2px;
}
</style>
